import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'projects/environments/environment'
import { BehaviorSubject, Observable, Subject, of } from 'rxjs'
import { share, tap } from 'rxjs/operators'
import { getToken } from 'src/app/auth/utils/token-utils'
import { ActionPlanService } from 'src/app/dashboard/services/action-plan/action-plan.service'
import { AvatarSuggestedData, User } from 'src/app/shared/interfaces/user'
import { ELegalNoticeVersion } from '../../../legal-notice/VERSION'
import { IS_SNACKBAR_SHOWED } from '../../../shared/http-contexts/show-snackbar.context'
import { PaginatedObject } from '../../../shared/interfaces/paginated-object'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly apiAuthUrl: string = `${environment.apiAuthUrl}user/`
  private readonly apiUserUrl: string = `${environment.apiUrl}user/`
  private readonly configUrl: string = `${environment.apiUrl}config/`
  readonly _user: BehaviorSubject<User> = new BehaviorSubject(null)
  readonly user$: Observable<User> = this._user.asObservable()

  public readonly userLogout$ = new Subject<void>()

  get user() {
    return this._user.getValue()
  }
  set user(user: User) {
    this._user.next(user)
  }

  private readonly _avatarPreview: BehaviorSubject<AvatarSuggestedData> = new BehaviorSubject(null)
  readonly avatarPreview$: Observable<AvatarSuggestedData> = this._avatarPreview.asObservable()

  constructor(
    private http: HttpClient,
    private router: Router,
    private actionServ: ActionPlanService,
    private zone: NgZone,
  ) {}

  getAvatar() {
    return this._avatarPreview.getValue()
  }

  setAvatarPreview(img) {
    this._avatarPreview.next(img)
  }

  getUser() {
    return this.http.get<User>(this.apiAuthUrl).pipe(
      tap((user) => {
        this.user = { ...this.user, ...user }
      }),
    )
  }

  uniqueGetUser() {
    if (getToken()) {
      return this.http.get(this.apiAuthUrl)
    } else {
      return of([])
    }
  }

  updateUser(
    partialUser: Partial<User>,
    config: { showSuccessSnack: boolean } = { showSuccessSnack: true },
  ): Observable<User> {
    if (partialUser.fe_flags) {
      partialUser.fe_flags = { ...this.user.fe_flags, ...partialUser.fe_flags }
    }

    if (this.user.anonymous) {
      config.showSuccessSnack = false
    }

    const obs$ = this.http
      .patch<User>(this.apiAuthUrl, partialUser, {
        context: new HttpContext().set(IS_SNACKBAR_SHOWED, config.showSuccessSnack),
      })
      .pipe(share())

    obs$.subscribe((user) => (this.user = { ...this.user, ...user }))

    return obs$
  }

  getUserStudyMode() {
    return this.http.get(this.configUrl)
  }

  deleteUser(deleteReason) {
    const options = {
      body: {
        reasons: deleteReason,
      },
    }
    return this.http.delete(this.apiUserUrl, options).pipe(tap(() => this.resetUser()))
  }

  uploadFile(img) {
    const formData = new FormData()
    formData.append('file', img, img.name)
    return this.http.post(`${environment.apiUrl}medias/`, formData)
  }

  updateAvatar(id, isPreAvatar = false) {
    const obj = isPreAvatar ? { generic_avatar_id: id } : { avatar: id }
    const obs$ = this.http.patch(this.apiAuthUrl, obj).pipe(share())
    obs$.subscribe((user) => (this.user = { ...this.user, ...user }))

    return obs$
  }

  getAllAvatarOfContentful() {
    return this.http.get<AvatarSuggestedData[]>(`${environment.apiUrl}medias/avatars/`)
  }

  unsubscribeNotification(obj) {
    return this.http.post(`${environment.apiUrl}email-unsubscription/`, obj)
  }

  logout(redirectTo?: string) {
    const { apiAuthUrl } = environment
    return this.http.post(`${apiAuthUrl}logout/`, null).subscribe(() => {
      this.resetUser()
      this.router.navigate([redirectTo ?? '/connexion'])
    })
  }

  initialize() {
    return this.http.get(this.apiAuthUrl).subscribe((user: User) => {
      this.user = { ...this.user, ...user }
    })
  }

  getActionPlanChoices() {
    return this.http.get(`${environment.apiUrl}action_plan_choices/`)
  }

  getUserActionPlan(id) {
    return this.http.get(`${environment.apiUrl}action_plan/?user=${id}`)
  }

  refreshAuthForNotification() {
    return this.http.head(this.apiAuthUrl)
  }

  getUserExclusion() {
    return this.http.get(`${this.apiAuthUrl}exclusions/`)
  }

  getUserEmailStatus() {
    return this.http.get<{ email_confirmed: boolean }>(`${environment.apiAuthUrl}email-status/`)
  }

  public createExtraData(payload: {
    first_name: string
    last_name: string
    age_group: string
    gender: string
    acquisition: string
    acquisition_other?: string
    signupNewsletter: boolean
  }) {
    return this.http
      .post<User>(`${environment.apiUrl}extra-data-inscription/`, payload)
      .pipe(tap((user) => this._user.next(user)))
  }

  public getExtraData(userId: number) {
    return this.http.get<PaginatedObject<{ key: string; data: string }>>(
      `${environment.apiUrl}user/${userId}/metadata/`,
    )
  }

  public acceptLegalNotices(
    userId: number,
    payload: {
      terms_accepted_version: ELegalNoticeVersion
      privacy_accepted_version: ELegalNoticeVersion
    },
  ) {
    return this.http.post<{ type: 'terms' | 'privacy' | 'cookies'; version: ELegalNoticeVersion }[]>(
      `${environment.apiUrl}user/${userId}/legal-notices/`,
      payload,
    )
  }

  private resetUser() {
    this.user = {} as User
    this.userLogout$.next()
    localStorage.clear()
    sessionStorage.clear()
  }
}
