import { Injectable } from '@angular/core'
import { TAlGroupId, TAlItemIds, TAlQuestionIds, TChoiceInternalId, TQuestionLabel } from './types'

@Injectable()
export class TranslationService {
  public readonly questions: {
    [key in TAlQuestionIds]?: TQuestionLabel
  } = {
    'diet-fruit-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.fruit:Combien de fois mangez-vous des fruits (frais, surgelés, en conserve, séchés, en compote ou en salade)?`,
    },
    'diet-vegetable-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.vegetable:Combien de fois mangez-vous des légumes (frais, surgelés, en conserve, séchés, en potage, en salade ou autres)?`,
    },
    'diet-vegetable-portion': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.vegetable.portion:Quelle part de votre assiette occupent les légumes (frais, surgelés, en conserve, séchés, en potage, en salade ou autres)?`,
    },
    'diet-whole-grain-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.whole-grain:Combien de fois mangez-vous des produits céréaliers?`,
    },
    'diet-whole-grain-details': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.whole-grain.details:Lorsque vous mangez des produits céréaliers, à quelle fréquence s’agit-il de grains entiers?`,
    },
    'diet-dairy-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.dairy:Combien de fois consommez-vous des produits laitiers ou de la boisson de soya enrichie?`,
    },
    'diet-deli-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.deli:Combien de fois mangez-vous des viandes rouges ou des charcuteries?`,
    },
    'diet-seafood-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.seafood:Combien de fois mangez-vous du poisson ou des fruits de mer (frais, surgelés, en conserve, séchés ou fumés)?`,
    },
    'diet-nut-seed-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.seed:Combien de fois mangez-vous des noix ou des graines (entières, en beurre ou en poudre)?`,
    },
    'diet-legume-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.legume:Combien de fois mangez-vous des légumineuses (en conserve, séchées, surgelées ou fraîches)?`,
    },
    'diet-oil-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.typical-week:Au cours d’une semaine typique...`,
      question: $localize`:@@life-habits.AL.translation-service.question.oil:Lorsque vous utilisez des matières grasses (pour la cuisson, la vinaigrette ou autres), à quelle fréquence s’agit-il d’huile d'olive ou d’huile de canola?`,
    },
    'diet-fast-food-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.in-general:Habituellement...`,
      question: $localize`:@@life-habits.AL.translation-service.question.fast-food:Combien de fois mangez-vous des repas préparés du commerce ou du restaurant?`,
    },
    'diet-sweet-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.in-general:Habituellement...`,
      question: $localize`:@@life-habits.AL.translation-service.question.sweet:Combien de fois mangez-vous des aliments sucrés du commerce?`,
    },
    'diet-sugar-drink-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.in-general:Habituellement...`,
      question: $localize`:@@life-habits.AL.translation-service.question.sugar-drink:Combien de fois buvez-vous des boissons sucrées ou diètes?`,
    },
    'diet-salt-frequence': {
      caption: $localize`:@@life-habits.AL.translation-service.question.in-general:Habituellement...`,
      question: $localize`:@@life-habits.AL.translation-service.question.salt:Combien de fois mangez-vous des grignotines salées?`,
    },
  }

  public readonly groupTranslation: { [key in TAlGroupId]: string } = {
    'diet-fruits-vegetables': $localize`:@@life-habits.AL.steps.fruits-and-vegetables:Fruits et légumes`,
    'diet-whole-grains': $localize`:@@life-habits.AL.steps.grain-products:Produits céréaliers`,
    'diet-protein': $localize`:@@life-habits.AL.steps.protein-foods:Aliments protéinés`,
    'diet-fat': $localize`:@@life-habits.AL.steps.oils:Huiles`,
    'diet-transformed': $localize`:@@life-habits.AL.steps.ultra-processed-foods:Aliments ultratransformés`,
  }

  public readonly itemTranslation: { [key in TAlItemIds]: string } = {
    'diet-fruit': $localize`:@@life-habits.AL.translation-service.item.fruit:Fruits`,
    'diet-vegetable': $localize`:@@life-habits.AL.translation-service.item.vegetable:Légumes`,
    'diet-grain-product': $localize`:@@life-habits.AL.translation-service.item.grain:Produits céréaliers`,
    'diet-dairy-alternative': $localize`:@@life-habits.AL.translation-service.item.dairy-alt:Produits laitiers et substituts`,
    'diet-red-meat-processed': $localize`:@@life-habits.AL.translation-service.item.red-meat:Viandes rouges et charcuteries`,
    'diet-seafood': $localize`:@@life-habits.AL.translation-service.item.seafood:Poisson et fruits de mer`,
    'diet-nut-seed': $localize`:@@life-habits.AL.translation-service.item.seed:Noix et graines`,
    'diet-legume': $localize`:@@life-habits.AL.translation-service.item.legume:Légumineuses`,
    'diet-olive-canola-oil': $localize`:@@life-habits.AL.translation-service.item.oil:Huiles`,
    'diet-prepared-meal': $localize`:@@life-habits.AL.translation-service.item.prepared-meal:Repas préparés du commerce ou restauration`,
    'diet-sweet': $localize`:@@life-habits.AL.translation-service.item.sweet:Aliments sucrés du commerce`,
    'diet-sugary-drink': $localize`:@@life-habits.AL.translation-service.item.sugary-drink:Boissons sucrées ou diètes`,
    'diet-salty-snack': $localize`:@@life-habits.AL.translation-service.item.salty-snack:Grignotines salées`,
  }

  public readonly choicesLabels: { [key in TChoiceInternalId]?: { label: string; formated: string } } = {
    '4-time-and-more-daily': {
      label: $localize`:@@life-habits.AL.choices.4-time-and-more-daily:4 fois par jour ou plus`,
      formated: $localize`:@@life-habits.AL.choices.4-time-and-more-daily.formated:<b>4 fois</b> par jour ou plus`,
    },
    '3-time-and-more-daily': {
      label: $localize`:@@life-habits.AL.choices.3-time-and-more-daily:3 fois par jour ou plus`,
      formated: $localize`:@@life-habits.AL.choices.3-time-and-more-daily.formated:<b>3 fois</b> par jour ou plus`,
    },
    '2-time-or-more-daily': {
      label: $localize`:@@life-habits.AL.choices.2-time-or-more-daily:2 fois par jour ou plus`,
      formated: $localize`:@@life-habits.AL.choices.2-time-or-more-daily.formated:<b>2 fois</b> par jour ou plus`,
    },
    '2-3-time-daily': {
      label: $localize`:@@life-habits.AL.choices.2-3-time-daily:2 à 3 fois par jour`,
      formated: $localize`:@@life-habits.AL.choices.2-3-time-daily.formated:<b>2 à 3 fois</b> par jour`,
    },
    '2-time-daily': {
      label: $localize`:@@life-habits.AL.choices.2-time-daily:2 fois par jour`,
      formated: $localize`:@@life-habits.AL.choices.2-time-daily.formated:<b>2 fois</b> par jour`,
    },
    '1-time-daily': {
      label: $localize`:@@life-habits.AL.choices.1-time-daily:1 fois par jour`,
      formated: $localize`:@@life-habits.AL.choices.1-time-daily.formated:<b>1 fois</b> par jour`,
    },
    'less-1-time-daily': {
      label: $localize`:@@life-habits.AL.choices.less-1-time-daily:Moins de 1 fois par jour`,
      formated: $localize`:@@life-habits.AL.choices.less-1-time-daily.formated:<b>Moins de 1 fois</b> par jour`,
    },
    '5-6-time-weekly': {
      label: $localize`:@@life-habits.AL.choices.5-6-time-weekly:5 à 6 fois par semaine`,
      formated: $localize`:@@life-habits.AL.choices.5-6-time-weekly.formated:<b>5 à 6 fois</b> par semaine`,
    },
    '3-4-time-weekly': {
      label: $localize`:@@life-habits.AL.choices.3-4-time-weekly:3 à 4 fois par semaine`,
      formated: $localize`:@@life-habits.AL.choices.3-4-time-weekly.formated:<b>3 à 4 fois</b> par semaine`,
    },
    '2-4-time-weekly': {
      label: $localize`:@@life-habits.AL.choices.2-4-time-weekly:2 à 4 fois par semaine`,
      formated: $localize`:@@life-habits.AL.choices.2-4-time-weekly.formated:<b>2 à 4 fois</b> par semaine`,
    },
    '3-time-or-more-weekly': {
      label: $localize`:@@life-habits.AL.choices.3-time-or-more-weekly:3 fois par semaine ou plus`,
      formated: $localize`:@@life-habits.AL.choices.3-time-or-more-weekly.formated:<b>3 fois</b> par semaine ou plus`,
    },
    '2-time-weekly': {
      label: $localize`:@@life-habits.AL.choices.2-time-weekly:2 fois par semaine`,
      formated: $localize`:@@life-habits.AL.choices.2-time-weekly.formated:<b>2 fois</b> par semaine`,
    },
    '2-time-or-less-weekly': {
      label: $localize`:@@life-habits.AL.choices.2-time-or-less-weekly:2 fois par semaine ou moins`,
      formated: $localize`:@@life-habits.AL.choices.2-time-or-less-weekly.formated:<b>2 fois</b> par semaine ou moins`,
    },
    '1-time-weekly': {
      label: $localize`:@@life-habits.AL.choices.1-time-weekly:1 fois par semaine`,
      formated: $localize`:@@life-habits.AL.choices.1-time-weekly.formated:<b>1 fois</b> par semaine`,
    },
    'less-1-time-weekly': {
      label: $localize`:@@life-habits.AL.choices.less-1-time-weekly:Moins de 1 fois par semaine`,
      formated: $localize`:@@life-habits.AL.choices.less-1-time-weekly.formated:<b>Moins de 1 fois</b> par semaine`,
    },
    '1-4-time-monthly': {
      label: $localize`:@@life-habits.AL.choices.1-4-time-monthly:1 à 4 fois par mois`,
      formated: $localize`:@@life-habits.AL.choices.1-4-time-monthly.formated:<b>1 à 4 fois</b> par mois`,
    },
    '1/4': {
      label: '1/4',
      formated: $localize`:@@life-habits.AL.choices.1/4.formated:<b>1/4</b> de`,
    },
    '1/2': {
      label: '1/2',
      formated: $localize`:@@life-habits.AL.choices.1/2.formated:<b>1/2</b> de`,
    },
    '3/4': {
      label: '3/4',
      formated: $localize`:@@life-habits.AL.choices.3/4.formated:<b>3/4</b> de`,
    },
    'every-day': {
      label: $localize`:@@life-habits.AL.choices.every-day:Tous les jours`,
      formated: $localize`:@@life-habits.AL.choices.every-day.formated:<b>Tous les jours</b>`,
    },
    'almost-always': {
      label: $localize`:@@life-habits.AL.choices.almost-always:Toujours ou presque`,
      formated: $localize`:@@life-habits.AL.choices.almost-always.formated:<b>Toujours ou presque</b>`,
    },
    often: {
      label: $localize`:@@life-habits.AL.choices.often:Souvent`,
      formated: $localize`:@@life-habits.AL.choices.often.formated:<b>Souvent</b>`,
    },
    sometime: {
      label: $localize`:@@life-habits.AL.choices.sometime:Parfois`,
      formated: $localize`:@@life-habits.AL.choices.sometime.formated:<b>Parfois</b>`,
    },
    never: {
      label: $localize`:@@life-habits.AL.choices.never:Jamais`,
      formated: $localize`:@@life-habits.AL.choices.never.formated:<b>Jamais</b>`,
    },
    all: {
      label: $localize`:@@life-habits.AL.choices.all:Toute`,
      formated: $localize`:@@life-habits.AL.choices.all.formated:<b>Toute</b>`,
    },
  }

  public getItemTranslation(key: TAlItemIds) {
    return this.itemTranslation[key]
  }
}
