import { MediaMatcher } from '@angular/cdk/layout'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Asset, ContentfulClientApi, Entry, createClient } from 'contentful'
import { MarkdownService } from 'ngx-markdown'
import { Observable, from, map, of, switchMap, tap } from 'rxjs'
import { ConfigService } from 'src/app/misc/config/config.service'
import { UserService } from 'src/app/misc/services/user/user.service'
import { environment } from '../../../../../environments/environment'
import { ContentfulConfig, TestimonialResponse } from '../interfaces/contentful'
import { Tag } from '../interfaces/tag'

type TClients = Observable<{
  content: ContentfulClientApi
  library: ContentfulClientApi
}>

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  limit = 10

  private readonly contentUrl = `${environment.apiUrl}contents`

  private currentArticle: any = null

  setCurrentArticle(art: any) {
    this.currentArticle = art
  }

  getCurrentArticle() {
    return this.currentArticle
  }

  private readonly clients$: TClients
  private config: ContentfulConfig

  constructor(
    private http: HttpClient,
    private markdownService: MarkdownService,
    private sanitize: DomSanitizer,
    private userService: UserService,
    configService: ConfigService,
    private media: MediaMatcher,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.clients$ = configService.config$.pipe(
      tap((config) => {
        this.config = {
          space: config.fe_contentful_space_id,
          accessToken: config.fe_contentful_token,
          environment: config.fe_contentful_environment,
          contentTypeIds: {
            faq: 'faq',
            category: 'category',
            article: 'article',
            content2Columns: 'content2Columns',
            deleteReason: 'deleteReason',
            testimonial: 'testimonial',
            libraryTags: 'library_tags',
          },
        }
      }),
      switchMap((config) =>
        of({
          content: createClient({
            space: config.fe_contentful_space_id,
            accessToken: config.fe_contentful_token,
            environment: config.fe_contentful_environment,
          }),
          library: createClient({
            space: config.library_contentful_space_id,
            accessToken: config.library_contentful_token,
            environment: config.library_contentful_environment,
          }),
        }),
      ),
    )
  }

  simplifyResourceArticle(resp) {
    return resp.map(({ fields, sys }) => {
      return {
        title: fields.title,
        excerpt: fields.excerpt,
        slug: fields?.slug,
        createdAt: sys.createdAt,
        updatedAt: sys.updatedAt,
        id: sys.id,
        url: fields.url ? fields.url : null,
        image: fields.image
          ? {
              info: fields.image?.fields,
              id: fields.image?.sys.id,
              createdAt: fields.image?.sys.createdAt,
            }
          : null,
        pdf: fields.pdf
          ? {
              info: fields.pdf?.fields,
              id: fields.pdf?.sys.id,
              createdAt: fields.pdf?.sys.createdAt,
            }
          : null,
      }
    })
  }

  // TODO CONTENTFUL
  /********** faq  *************/
  getContentTypeEntriesFAQ(faqId: string, locale: string) {
    return this.clients$.pipe(
      switchMap(({ content }) =>
        from(
          content
            .getEntries({
              'fields.currentActiveFaq': true,
              content_type: this.config.contentTypeIds.faq,
              select: 'sys.id,fields.sections',
              include: 3,
              'sys.id': faqId,
              locale,
            })
            .then((response) => this.simplifyFaq(response.items)[0])
            .catch(console.error),
        ),
      ),
    )
  }

  simplifyFaq(res) {
    return res.map((e) => {
      e.fields = e.fields.sections.map((section) => {
        return {
          id: section.sys.id,
          fields: section.fields,
        }
      })
      return {
        sections: e.fields,
        id: e.sys.id,
      }
    })
  }
  /********** end faq  *************/
  // from other services
  // ArticleContentService

  // TODO CONTENTFUL
  getElementById(id, isLibrary?) {
    return from(this.clients$).pipe(
      map((clients) => (isLibrary ? clients.library : clients.content)),
      switchMap((client) => from(client.getEntry(id, { locale: this.userService.user?.locale }).catch(console.error))),
    )
  }

  markedToHtml(string) {
    return this.markdownService.parse(string)
  }

  buildExternalReference(): string {
    return ''
  }

  formatHTML(data, extraData?) {
    let o = 0
    let allIndexOfRes = []
    let dejaferme = false
    const allData = this.getCurrentArticle()
    const keys: any = {
      Entry: 'entries',
      Asset: 'assets',
    }

    const options = {
      renderNode: {
        'embedded-asset-block': (node) => {
          if (allData) {
            return `<picture>
                  <source
                    srcset='${allData?.data[keys[node.data.target.sys.linkType ? node.data.target.sys.linkType : node.data.target.sys.type]][node.data.target.sys.id].file.url}?w=1800&fm=avif'
                    type="image/avif">
                  <source
                    srcset='${allData?.data[keys[node.data.target.sys.linkType ? node.data.target.sys.linkType : node.data.target.sys.type]][node.data.target.sys.id].file.url}?w=1800&fm=webp'
                    type="image/webp">
                  <img
                    class='img-question mx-auto'
                    src='${allData?.data[keys[node.data.target.sys.linkType ? node.data.target.sys.linkType : node.data.target.sys.type]][node.data.target.sys.id].file.url}?w=1800'>
                </picture>`
          } else {
            return `<picture>
                  <source srcset='${node.data.target.fields.file.url}?w=1800&fm=avif' type="image/avif">
                  <source srcset='${node.data.target.fields.file.url}?w=1800&fm=webp' type="image/webp">
                  <img class='img-question' src='${node.data.target.fields.file.url}?w=1800'>
                </picture>`
          }
        },
        [INLINES.HYPERLINK]: (node, next) => {
          return `<a href='${node.data.uri}' target='_blank'>${next(node.content)}</a>`
        },
        [BLOCKS.QUOTE]: (node, next) => {
          const extra = extraData?.author
            ? `<p class='citation-author my-5 font-sans font-normal text-xl flex justify-end' >${extraData?.author}</p>`
            : ''
          let quoteImg = ''
          if (this.locale === 'en') {
            if (extraData?.position % 3 === 0 && !this.media.matchMedia('(min-width: 1024px)').matches) {
              quoteImg = '<img src=\'./assets/img/quote_ENG_white.svg\' class=\'frame-icon w-full max-w-[50px] mb-4\'/>'
            } else {
              quoteImg = '<img src=\'./assets/img/quote_ENG_blue.svg\' class=\'frame-icon w-full max-w-[50px] mb-4\'/>'
            }
          } else {
            if (extraData?.position % 3 === 0 && !this.media.matchMedia('(min-width: 1024px)').matches) {
              quoteImg = '<img src=\'./assets/img/icon_quotes_white.svg\' class=\'frame-icon w-full max-w-[50px] mb-4\'/>'
            } else {
              quoteImg = ' <img src=\'./assets/img/icon_quotes_blue.svg\' class=\'frame-icon w-full max-w-[50px] mb-4\'/>'
            }
          }
          return `<div class='citationB ${this.media.matchMedia('(min-width: 1024px)').matches ? 'ml-16' : ''}  mt-14 flex flex-col font-bold ${this.media.matchMedia('(min-width: 1024px)').matches ? 'max-w-[75%]' : ''} ${extra ? 'mb-0' : 'mb-14'} !italic !text-2xl'>
            ${quoteImg}
            ${next(node.content)}
            ${extra}
          </div>`
        },
        'embedded-entry-block': (node) => {
          if (allData) {
            let currentNodeData =
              allData?.data[
                keys[node?.data?.target?.sys?.linkType ? node.data.target.sys.linkType : node?.data?.target?.sys?.type]
              ][node.data.target.sys.id]
            if (
              node?.data?.target?.sys?.contentType?.sys?.id === 'contentImages' ||
              currentNodeData?.type === 'contentImages'
            ) {
              let content = '<figure class="my-4 relative">'
              if (node?.data?.target?.fields?.mainImage) {
                content += `<picture>
                  <source srcset='${node?.data?.target?.fields?.mainImage?.fields?.file?.url}?w=1800&fm=avif' type="image/avif">
                  <source srcset='${node?.data?.target?.fields?.mainImage?.fields?.file?.url}?w=1800&fm=webp' type="image/webp">
                  <img title='${node?.data?.target?.fields?.mainImage?.fields?.title}'
                    class='md:block hidden w-full rounded-3xl' src='${node?.data?.target?.fields?.mainImage?.fields?.file?.url}?w=1800' alt="${node?.data?.target?.fields?.description}">
                </picture>`
              } else if (!node?.data?.target?.fields?.mainImage && currentNodeData?.mainImage) {
                content += `<picture>
                  <source srcset='${currentNodeData?.mainImage?.fields?.file?.url}?w=1800&fm=avif' type="image/avif">
                  <source srcset='${currentNodeData?.mainImage?.fields?.file?.url}?w=1800&fm=webp' type="image/webp">
                  <img title='${currentNodeData?.mainImage?.fields?.title}'
                    class='md:block hidden w-full rounded-3xl'
                    src='${currentNodeData?.mainImage?.fields?.file?.url}?w=1800'
                    alt="${currentNodeData?.mainImage?.fields?.description}">
                </picture>`
              }

              if (node?.data?.target?.fields?.mainImageMobile) {
                content += `<picture>
                  <source srcset='${node?.data?.target?.fields?.mainImageMobile?.fields?.file?.url}?w=1800&fm=avif' type="image/avif">
                  <source srcset='${node?.data?.target?.fields?.mainImageMobile?.fields?.file?.url}?w=1800&fm=webp' type="image/webp">
                  <img title='${node?.data?.target?.fields?.mainImageMobile?.fields?.title}' alt='content?.fields?.mainImage?.fields?.description'
                    class='block md:hidden w-full rounded-3xl'
                    src='${node?.data?.target?.fields?.mainImageMobile?.fields?.file?.url}?w=1800'
                    alt="${node?.data?.target?.fields?.description}">
                </picture>`
              } else if (!node?.data?.target?.fields?.mainImageMobile && currentNodeData?.mainImageMobile) {
                content += `<picture>
                <source srcset='${currentNodeData?.mainImageMobile?.fields?.file?.url}?w=1800&fm=avif' type="image/avif">
                <source srcset='${currentNodeData?.mainImageMobile?.fields?.file?.url}?w=1800&fm=webp' type="image/webp">
                <img title='${currentNodeData?.mainImageMobile?.fields?.title}'
                  class='block md:hidden w-full rounded-3xl'
                  src='${currentNodeData?.mainImageMobile?.fields?.file?.url}?w=1800'
                  alt="${currentNodeData?.mainImageMobile?.fields?.description}">
              </picture>`
              }

              if (node?.data?.target?.fields?.legend) {
                content += `
                <figcaption [innerHTML]='${node?.data?.target?.fields?.legend}' class='font-sans text-sm text-center text-blue-400'>
                </figcaption>`
              } else if (!node?.data?.target?.fields?.legend && currentNodeData?.legend) {
                content += `
                <figcaption [innerHTML]='${currentNodeData?.legend}' class='font-sans text-sm text-center text-blue-400'>
                </figcaption>`
              }
              content += '</figure>'
              return content
            } else {
              data?.content?.forEach((element, index) => {
                let checkUrl: any = null
                if (element?.data?.target?.sys) {
                  checkUrl =
                    allData?.data[
                      keys[
                        element?.data?.target?.sys?.linkType
                          ? element.data.target.sys.linkType
                          : element?.data?.target?.sys?.type
                      ]
                    ][element.data.target.sys.id]
                }
                if (element?.nodeType === 'embedded-entry-block' && (!checkUrl || !checkUrl?.url)) {
                  allIndexOfRes = [...allIndexOfRes, index]
                }
              })

              if (node?.data?.target?.sys?.id === allData.article_id) {
                currentNodeData = allData.data.article_data
              }
              for (const k in currentNodeData) {
                if (typeof currentNodeData[k] === 'object' && 'sys' in currentNodeData[k]) {
                  const tmp = allData.data[keys[currentNodeData[k].sys?.linkType]][currentNodeData[k].sys?.id]
                  currentNodeData[k] = { ...currentNodeData[k], ...tmp }
                }
              }

              //fix a ameliorer ext et int article
              currentNodeData = currentNodeData ? currentNodeData : allData?.data?.article_data
              if (currentNodeData?.url) {
                let st = ''
                let end = ''
                allIndexOfRes = [...new Set(allIndexOfRes)]

                if (allIndexOfRes[o] === allIndexOfRes[0] || dejaferme) {
                  st = '<div class="flex flex-col md:flex-row flex-wrap">'
                  dejaferme = false
                }

                if (!dejaferme) {
                  if (allIndexOfRes.length - 1 >= o + 1) {
                    if (!(allIndexOfRes[o] + 1 === allIndexOfRes[o + 1])) {
                      end = '</div>'
                      dejaferme = true
                    }
                  } else if (allIndexOfRes.length - 1 <= 0) {
                    end = '</div>'
                    dejaferme = true
                  }
                }
                o++
                const imgB = currentNodeData?.logo?.file?.url
                  ? ` <img class='!rounded-2xl' src='${currentNodeData?.logo?.file?.url}'/>`
                  : ''
                // tslint:disable-next-line:max-line-length
                const extUrl = currentNodeData?.url
                  ? currentNodeData?.url
                  : currentNodeData?.pdf
                    ? currentNodeData?.pdf?.file?.url
                    : ''
                let allTags = ''
                allData?.data?.article_data?.tags?.forEach((element) => {
                  if (element?.fields?.icon?.fields?.file?.url) {
                    allTags += `<span class='ml-2'><img src='${element?.fields?.icon?.fields?.file?.url}' alt='${element?.fields?.icon?.fields?.title}' class='frame-icon' /></span>`
                  }
                })
                return `
                ${st}
                <a target='_blank' href='${extUrl}' class='resource-item-externe inline-flex flex-col p-3 mb-4 md:mr-4  rounded-3xl bg-white md:border md:border-grey-400 w-[414px] h-[282px] max-w-full'
                  >
                  <div class='mb-4'>
                    <div class='flex justify-between mb-4'>
                      <div class='logo w-[72px]'>${imgB}</div>
                      <div class='tags h-[29px] flex items-center'>${allTags}</div>
                    </div>
                    <div class='font-buenos font-bold text-xl mb-2 line-clamp-2'>${currentNodeData?.title}</div>
                    <div class='text font-medium line-clamp-3'> ${currentNodeData?.excerpt}</div>
                  </div>
                  <div class='action flex justify-end mt-auto'>
                    <img class='!rounded-none' src='./assets/img/open_in_new.png' alt=''>
                  </div>
                </a>${end}`
              } else {
                return this.createInterneArticleTemplate(currentNodeData, node.data?.target?.sys?.id)
              }
            }
          }
        },
      },
    }
    return this.sanitize.bypassSecurityTrustHtml(
      this.transformHTMLEntities(documentToHtmlString(data, options), [{ entity: '&amp;nbsp;', value: '&nbsp;' }]),
    )
  }

  transformHTMLEntities(string: any, htmlEntities: Array<any>) {
    let transformedString = string
    if (htmlEntities.length) {
      htmlEntities.forEach((ele: any) => {
        transformedString = transformedString.replaceAll(ele.entity, ele.value)
      })
    }
    return transformedString
  }

  createInterneArticleTemplate(article, id): string {
    const intUrl = article.pdf ? (article.pdf.file?.url ?? article.pdf.fields.file.url) : '/articles/' + id
    const imgUrl = article.image.file?.url ?? article.image.fields.file.url
    return `
      <a class='h-[100px] lg:h-[140px] mb-2 flex flex-row !no-underline pendo_article_${article.title.replace(/\s/g, '')}_link' href='${intUrl}' target='${article.pdf ? '_blank' : ''}'>
        <div class='rounded-l-2xl'>

          <picture class='block w-[100px] md:w-[120px] lg:w-[170px]'>
            <source srcset='${imgUrl}?w=520&fm=avif'
            type="image/avif">
            <source srcset='${imgUrl}?w=520&fm=webp'
            type="image/webp">
            <img class='object-cover object-center w-[100px] md:w-[120px] lg:w-[170px] h-[100px] lg:h-[140px] rounded-l-2xl'
              src='${imgUrl}?w=520'>
          </picture>

        </div>

        <div class='p-3 bg-white flex flex-col rounded-r-2xl w-[100vw] gap-1'>
          <span class='font-buenos !line-clamp-2 !lg:line-clamp-1 text-lg lg:text-xl font-bold'>
            ${article.title}
          </span>
          <span class='hidden leading-5 text-base lg:!line-clamp-2 grow-1'>
            ${article.excerpt}
          </span>
        </div>
      </div>
      </a>
    `
  }

  getArticleInfoFromBackEnd(id: string) {
    return this.http.get(`${environment.apiUrl}articles/${id}/`)
  }

  //userservice
  getContentTypeEntriesReason(langue, deep?) {
    return this.clients$.pipe(
      switchMap(({ content }) =>
        from(
          content
            .getEntries({
              'fields.visibleUser': true,
              content_type: this.config.contentTypeIds.deleteReason,
              locale: langue,
              include: deep ? deep : 1,
            })
            .then((response) => response.items)
            .catch(console.error),
        ),
      ),
    )
  }

  getTestimonial(): Observable<TestimonialResponse[]> {
    return this.http.get<TestimonialResponse[]>(`${this.contentUrl}/testimonials/`)
  }

  getEntriesByIds<T>(entriesId: string[]) {
    return this.clients$.pipe(
      switchMap(({ content }) =>
        from(
          content
            .getEntries<T>({
              'sys.id[in]': entriesId.toString(),
              locale: `${this.locale}-CA`,
            })
            .then((entries) => entries.items)
            .catch<Entry<T>[]>(() => []),
        ),
      ),
    )
  }

  getAllTags(): Observable<Tags[]> {
    return this.clients$.pipe(
      switchMap(({ library }) =>
        from(
          library
            .getEntries({
              content_type: this.config.contentTypeIds.libraryTags,
              locale: `${this.locale}-CA`,
            })
            .then((result) => result.items.map<Tags>((item) => ({ id: item.sys.id, name: item.fields['name'] }))),
        ),
      ),
    )
  }

  getThemeDetail(themeId: number) {
    return this.http.get<Tag>(`${environment.apiUrl}articles/tags/`, {
      params: new HttpParams().append('tag_id', themeId),
    })
  }
}

export type ArticleAsset = Omit<Asset, 'fields'> & {
  fields: {
    title: string
    image: {
      file: {
        url: string
        details: {
          size: number
          image?: {
            width: number
            height: number
          }
        }
        fileName: string
        contentType: string
      }
    }
    file: { url: string }
    logo: { fields: { file: { url: string } } }
    excerpt: string
    url: string
  }
}

export interface ExternalArticle {
  id: string
  title: string
  imageUrl: string
  description: string
  externalLink: string
}

export interface Tags {
  id: string
  name: string
}
